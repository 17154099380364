<template lang="html">
  <div class="review tac p2 hero-margin">
    <!-- <blockquote>
      <p>{{reviews.review}}</p>
      <h5>{{reviews.reviewer}}</h5>
      <small>{{reviews.designation}}</small>
    </blockquote> -->
    <img
      width="240"
      :src="reviews.src"
      alt="image of reviewer"
      class="review-img mb3"
    />
    <p class="d-center mb3">{{ reviews.review }}</p>
    <h5>{{ reviews.reviewer }}</h5>
    <small>{{ reviews.designation }}</small>
  </div>
</template>

<script>
export default {
  name: "Review",
  props: ["reviews"]
};
</script>

<style lang="scss">
@media all and (min-width: 960px) {
  .review p {
    max-width: 60%;
    text-indent: 20px;
    line-height: 1.6;
    font-size: 18px;
  }

  .review {
    // width: 80vw;
  }
}
@media all and (min-width: 1200px) {
  .review p {
    max-width: 80%;
  }

  .review {
    width: 80vw;
  }
}
.review {
  // background-color: rgb(250, 235, 215);
  // background-color: rgba(192, 179, 140, 0.826);

  .review-img {
    border-radius: 50%;
    width: 240px !important;
  }
}

@media all and (min-width: 1680px) {
  .review {
    width: 60vw;
  }
}
</style>
