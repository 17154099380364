<template>
  <table class="pt3 mt3 d-center">
    <thead class="pt3 pb3 mb3">
      <tr>
        <th class="opt-pad">S/No</th>
        <th class="opt-pad">Flat No</th>
        <th class="opt-pad">Area (Sqft)</th>
        <th class="opt-pad">UDS (Sqft)</th>
        <th class="opt-pad">BHK</th>
        <th class="opt-pad">Booking</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="opt-pad">1</td>
        <td class="opt-pad">GN1</td>
        <td class="opt-pad">1158</td>
        <td class="opt-pad">662</td>
        <td class="opt-pad">3</td>
        <td class="opt-pad c-green">
          <a href="tel:+919842430871">Book now</a>
        </td>
      </tr>
      <tr>
        <td class="opt-pad">2</td>
        <td class="opt-pad">GS1</td>
        <td class="opt-pad">1009</td>
        <td class="opt-pad">577</td>
        <td class="opt-pad">2</td>
        <td class="opt-pad c-green">
          <a href="tel:+919842430871">Book now</a>
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td>GS2</td>
        <td>1092</td>
        <td>624</td>
        <td>2</td>
        <td class="opt-pad c-green">
          <a href="tel:+919842430871">Book now</a>
        </td>
      </tr>
      <tr>
        <td>4</td>
        <td>FN1/SN1</td>
        <td>1282</td>
        <td>732</td>
        <td>3</td>
        <td class="opt-pad c-green">
          <a class="cup" @click="scrollTo({ route: '/project', id: 'fn1' })"
            >Book now</a
          >
        </td>
      </tr>
      <tr>
        <td>5</td>
        <td>FN2/SN2</td>
        <td>971</td>
        <td>555</td>
        <td>2</td>
        <td class="opt-pad c-green">
          <a class="cup" @click="scrollTo({ route: '/project', id: 'fn2' })"
            >Book now</a
          >
        </td>
      </tr>
      <tr>
        <td>6</td>
        <td>FN3/SN3</td>
        <td>1153</td>
        <td>659</td>
        <td>3</td>
        <td class="opt-pad c-green">
          <a class="cup" @click="scrollTo({ route: '/project', id: 'fn3' })"
            >Book now</a
          >
        </td>
      </tr>
      <tr>
        <td>7</td>
        <td>FS1/SS1</td>
        <td>1009</td>
        <td>577</td>
        <td>2</td>
        <td class="opt-pad c-green">
          <a class="cup" @click="scrollTo({ route: '/project', id: 'fs1' })"
            >Book now</a
          >
        </td>
      </tr>
      <tr>
        <td>8</td>
        <td>FS2/SS2</td>
        <td>1083</td>
        <td>619</td>
        <td>2</td>
        <td class="opt-pad c-green">
          <a class="cup" @click="scrollTo({ route: '/project', id: 'fs2' })"
            >Book now</a
          >
        </td>
      </tr>
      <tr>
        <td>9</td>
        <td>FS3/SS3</td>
        <td>1250</td>
        <td>714</td>
        <td>3</td>
        <td class="opt-pad c-green">
          <a class="cup" @click="scrollTo({ route: '/project', id: 'fs3' })"
            >Book now</a
          >
        </td>
      </tr>
      <tr>
        <td>10</td>
        <td>FS4/SS4</td>
        <td>1447</td>
        <td>827</td>
        <td>3</td>
        <td class="opt-pad c-green">
          <a class="cup" @click="scrollTo({ route: '/project', id: 'fs4' })"
            >Book now</a
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import zenscroll from "zenscroll";
import router from "@/router";

export default {
  name: "Table",
  methods: {
    scrollTo(to) {
      if (router.currentRoute.path != to.route) {
        // document.getElementById(to.id).click();
        router.push(to.route, () => {
          setTimeout(() => {
            let id = document.getElementById(to.id);
            zenscroll.to(id);
          }, 500);
        });
      } else {
        let id = document.getElementById(to.id);
        zenscroll.to(id);
      }
    }
  }
};
</script>

<style lang="scss">
.opt-pad {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

@media all and (min-width: 320px) {
  .opt-pad {
    padding: 0.2rem 0.3rem;
  }
}

table {
  background-color: #f1efe9;
  box-shadow: 2px 2px rgb(98, 73, 73);
  padding: 10px;
  font-size: 80%;

  @media all and (min-width: 768px) {
    padding: 30px;
    font-size: 140%;
  }
}
</style>
