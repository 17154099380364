<template>
  <li class="nav-item" @mouseover="mover" @mouseleave="mleave">
    <slot />
    <div class="ddm-container" v-if="hover">
      <slot name="menu"></slot>
    </div>
  </li>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "Dropdown",
  data() {
    return {
      hover: false
    };
  },
  methods: {
    mover: function() {
      this.hover = true;
    },
    mleave: function() {
      this.hover = false;
    }
  }
};
</script>
