<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Carousel :images="images"></Carousel>
    <h1 class="c">
      A warm welcome from <span class="times ttu">MV Builders</span>
    </h1>
    <!-- <div class="warn-site c">
      <p><mark>please note:</mark> The website mvbuilders.in is under Construction.</p>
      <p>You may see irrelevant information and layout during this period.</p>
    </div> -->
    <div class="c tac">
      <h2>
        Our Current Project <br />
        <small class="times ttu">MV Maithri Homes</small>
      </h2>
    </div>
    <!-- <div class="c theme-2">
      <h1 class="c-red ml3 pl3 pulse-grow pb3 mb3">Hurry Up ! Buy Now</h1>
      <p class="fz-160p pl3 ml3">
        <span class="fz-160p c-red nowrap-text">Hurry up!</span>
        Already 17 people registered their home in MV Vistara and
        <span class="c-green">only 3 homes left</span> for sale. Visit the home
        and make it yours.
        <router-link @click.native="$scrollToTop" to="/contact"
          >Click Here for Contact details
        </router-link>
        or <a class="" href="tel:+919842430871">call +91 9842430871</a>
      </p>
    </div> -->
    <div class="row c">
      <div class="col-md2">
        <img
          src="/mvm.jpg"
          width="704"
          alt="image of MV Maithri"
          class="mw-100"
        />
        <a class="vam mt2" href="/M V MAITHRI BROUCHER.pdf"
          ><img
            width="50"
            height="60"
            src="@/assets/pdf_file.png"
            alt="pdf file icon"
          /><span class="vab fz-140p">Download MV MAITHRI Brochure</span></a
        >
        <br />
      </div>
      <div class="col-md2">
        <h3 class="ttu">MV Maithri</h3>
        <p>
          Happy to launch our new project in Boologa Vaikundam, Srirangam, where
          everyone in the Universe have an ambition to live during their
          lifetime, especially during their retired livelihood. Our project is
          situated very near to Thayar Sannidhi which is helpful for easy
          navigation from home. MV MAITHRI provides a passenger lift with ARD
          (Automatic Rescue Device) facility to cater the needs of all walks of
          life. We assure MV MAITHRI will fulfill the need for benevolent
          moterly homes with a compact architecture surrounded by soulful
          temples and lovable people.
          <!-- ambitions of Srirangam-loving people spread all over the world. -->
        </p>
        <h4>Location Advantages</h4>
        <ul class="list-checked">
          <li><span>Around 1km from Srirangam Thayar Sannadhi.</span></li>
          <li><span>Lush Green Environment</span></li>
          <li><span>2km from Bus Stand/Railway Station.</span></li>
          <li><span>3km from Government Butterfly Park.</span></li>
        </ul>
      </div>
    </div>
    <div class="c">
      <iframe
        title="google map of MV Maithri"
        class="gmapvistara"
        src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d3918.2920565568234!2d78.68275382587682!3d10.86537675754501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x3baaf677a73db341%3A0x9722303799dcab1b!2sSri%20Ranganayaki%20Thaayaar%20Sannadhi%2C%20North%20Uthrai%20Street%2C%20Sriramapuram%2C%20Tiruchirappalli%2C%20Tamil%20Nadu!3m2!1d10.8639689!2d78.6890763!4m5!1s0x3baaf7634fdc8d79%3A0xa59d0a9f8c07f8c1!2sgoogle%20map%20mv%20maithri!3m2!1d10.8679346!2d78.6811048!5e0!3m2!1sen!2sin!4v1691932857472!5m2!1sen!2sin"
        height="450"
        frameborder="0"
        style="border:0"
        allowfullscreen
      ></iframe>
    </div>
    <div class="d-center tac">
      <a class="vam mt2" href="/MVB DRAWING.pdf">
        <img
          class="mw-100"
          src="/drawingplan.jpg"
          alt="Drawing Plan of MV MAITHRI"
        />
        <br />
        <img
          width="50"
          height="60"
          src="@/assets/pdf_file.png"
          alt="pdf file icon"
        /><span class="vab fz-140p">Download MV MAITHRI Plan Drawing</span></a
      >
    </div>
    <section id="sales" class="c tac hero-margin">
      <h3 class="ttu">MV Maithri buildup details</h3>
      <Table></Table>
    </section>

    <div class="hero-margin c tac">
      <img
        src="/mvmaithri-poster.webp"
        width="1200"
        alt="image of MV Maithri"
        class="mw-100"
      />
    </div>

    <div class="cta-bg">
      <div class="c ml2 tac">
        <h2 class="c-white mt3">Book your Home now:</h2>
        <div class="banner row flex-jcc">
          <div class="cbrpm col-md2">
            <a class="" href="tel:+919842430871"
              ><img
                src="@/assets/cta-call.png"
                alt="smartphone ringing icon"
                class="mw-100"
            /></a>
          </div>
          <div class="cbrpm col-md2">
            <a
              aria-label="Chat on WhatsApp"
              class="no-print"
              href="https://wa.me/919842430871"
            >
              <img
                alt="Chat on WhatsApp"
                class="mw-100"
                src="@/assets/WhatsAppButtonGreenLarge.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="c mt3">
      <h2 class="page-break center-heading">
        Words from our customers
      </h2>
    </div>
    <div class="c">
      <div :key="componentKey" class="c review-slider print-small">
        <!-- window.innerWidth on slides instead of w-100p -->
        <div class="review-c">
          <Review :reviews="reviews[0]"></Review>
        </div>
        <div class="review-c page-break page-break-after">
          <Review :reviews="reviews[1]"></Review>
        </div>
        <div class="review-c">
          <Review :reviews="reviews[2]"></Review>
        </div>
      </div>
    </div>
    <!-- <img src="@/assets/main_cover.jpg" alt="image of Srirangam Temple" class=""> -->
    <div
      class="h-500 page-break"
      style="background: url('/main_cover.jpg') 30% 0% no-repeat; background-size: cover;"
    >
      <div class="row">
        <div class="col-md2"></div>
        <div class="col-md2 c lg-hide">
          <!-- <h2 class="c c-white">Temple City</h2>
          <h3 class="c c-white">Peaceful place for you</h3> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from "@/components/Carousel.vue";
import Review from "@/components/Review.vue";
import Table from "@/components/Table.vue";
import { tns } from "@/../node_modules/tiny-slider/src/tiny-slider";
import "@/../node_modules/tiny-slider/dist/tiny-slider.css";
import * as help from "@/js/helpers.js";

export default {
  name: "home",
  components: {
    Carousel,
    Table,
    Review
  },
  data() {
    return {
      componentKey: 0,
      images: [
        {
          src: require("@/assets/mvm.jpg"),
          alt: "MV Builders",
          heading: "MV Builders",
          caption: "Friendly Companion for Construction Business"
        },
        {
          src: require("@/assets/mvmm.jpg"),
          alt: "MV Maithri",
          heading: "MV Maithri",
          caption: "Compacted with benevolence"
        },
        {
          src: require("@/assets/mvme.jpg"),
          alt: "MV Maithri",
          heading: "MV Maithri",
          caption: "Compacted with benevolence"
        }
      ],
      reviews: [
        {
          review:
            "This new project ventured by M.V. Builders will certainly bring feel of confidence and Security to those who will take advantage of buying. Mr. K. Muraliidharan is quite dedicated in giving quality product to the society and ensures best is given irrespective of the challenges which comes along the project. I wish all success to Mr. K. Muralidharan and team.",
          reviewer: "Venkatramanan. M",
          designation: "chaitanya kutir owner",
          src: require("@/assets/rv1.jpg")
        },
        {
          review:
            "We purchased a flat from MV Builders for my mother in law who lives by herself. We were looking for safety, security and serenity to make sure she can live happily and peacefully in a place that she can call 'Home'. We are very pleased with this purchase. She has been living in MV Vistara for over two years now with utmost comfort. The flat is quite spacious, has sophisticated fixtures, such as a false ceiling, bringing in style and quality at the same time. She doesn't feel lonely because the neighbourhood is friendly and supportive. MV Vistara's association and residents conduct poojas and other religious rituals at regular intervals that bring her a lot of inner peace. When we visited MV Vistara, we loved the clean air and scenic beauty in and around the flat. Many thanks to Shri K. Muralidharan, the builder of MV Vistar, who provided us a great value for money and offered additional facilities such as common mailbox to the residents. We can't ask for more.",
          reviewer: "Mrs. Gayathri Gowri Shankar",
          designation: "MV Vistara Client",
          src: require("@/assets/rv2.jpg")
        },
        {
          review:
            "It's been a pleasure and satisfying experience to do Business with Mr. K. Muralidharan (M.V.Builders). They are a strong team of people & project after project the outcomes have only got better & better. MV builders never compromise quality and detailing, exceptional design and craftsmanship. Best part is their friendly approach, transparent transactions and perfect documentations. Strategic location for most of the projects is also a plus. In total, a property from M.V.Builders is fairly priced , value for money and a best home.",
          reviewer: "Mrs. HARINI and Mr. ARVIND.",
          designation: "MV Pranavm, MV Brindhavan clients",
          src: require("@/assets/rv3.jpg")
        }
      ]
    };
  },
  mounted() {
    let reviewSlider = tns({
      container: ".review-slider",
      items: 1,
      mode: "carousel",
      // lazyload: true,
      nav: true,
      // navPosition: "top",
      // gutter: -100,
      edgePadding: 100,
      // mouseDrag: true,
      controls: true,
      arrowKeys: true,
      speed: 1700,
      controlsText: ["<", ">"],
      autoplayButtonOutput: false,
      // autoplayHoverPause: true,
      // autoHeight: true,
      // fixedWidth: (function() {
      //   return window.innerWidth - window.innerWidth / 8;
      // })(),
      autoWidth: true,
      slideBy: "page",
      // slideBy: 1,
      autoplay: true,
      autoplayTimeout: 5000,
      responsive: {
        300: {
          disable: true
        },
        960: {
          disable: false,
          edgePadding: 0,
          autoWidth: false,
          fixedWidth: window.innerWidth
        },
        1100: {
          edgePadding: 50
        }
      }
    });

    // let printMQL = window.matchMedia("print");

    // addListener is for backward compatibility use addEventListener
    // printMQL.addListener(function(mql) {
    //   console.log(new Error().stack);
    //   if (mql.matches) {
    //     reviewSlider.destroy();
    //   }
    // });

    // printMQL.addEventListener("change", () => {
    //   reviewSlider.destroy();
    // });

    // printMQL.onchange = function(mql) {
    //   alert("hello onpmql" + Date.now());
    //   console.trace();
    //   console.log(new Error().lineNumber || new Error().stack)
    //   if (mql.matches) {
    //     reviewSlider.destroy();
    //   }
    // }

    window.onbeforeprint = function() {
      reviewSlider.destroy();
    };

    window.onafterprint = function() {
      reviewSlider.rebuild();
    };

    // try vue js component reload
    var that = this;
    // var rslides = document.querySelectorAll('.w-100p');

    var rebuildSlides = help.debounce(
      function() {
        // var rslides = document.querySelectorAll('.w-100p p');
        // for (var i = 0; i < rslides.length; i++) {
        //   rslides[i].style.width = window.innerWidth;
        // }
        // slider.destroy();
        // slider = slider.rebuild();
        // console.log("destroyed");
        // setTimeout(function() {
        //   slider = slider.rebuild();
        //   // slider.refresh();
        // }, 116);
        // console.log(slider); // vuejs component remount
        // // slider.refresh();

        if (that.$route.name === "home") {
          // console.log(that.$route.name === "home");
          // that.$router.push({ path: '/' });
          // that.$router.go();
          // that.$data.componentKey++;
          // slider = slider.rebuild();
        }
        // location.reload();
      },
      250,
      false
    );

    window.addEventListener("resize", rebuildSlides);
  }
};
</script>

<style lang="scss">
.home .tns-inner {
  // background-color: rgb(250, 235, 215);
}

.tns-controls {
  position: relative;
  top: 300px;
  z-index: 20;
}

button[data-controls] {
  background-color: rgba(0, 0, 0, 0.7);
  border: 0;
  color: white;
  width: 30px;
  height: 40px;
  font-size: 24px;
}

button[data-controls="next"] {
  position: absolute;
  right: 10px;
}

button[data-controls="prev"] {
  margin-left: 10px;
}

.tns-nav button {
  width: 17px;
  height: 17px;
  background-color: #000;
  border: 2px solid white;
  border-radius: 50%;
  margin-right: 4px;
}

.gmapvistara {
  width: 100%;
}

.cpr {
  padding: 1rem 2rem;
  border-radius: 21%;
}

.cbrpm {
  @media all and (min-width: 768px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  margin-top: 20px;
  margin-bottom: 20px;
}

.center-heading {
  @media all and (min-width: 384px) {
    text-indent: 10px;
  }
  padding-top: 50px;
  padding-bottom: 0;
  margin-bottom: 0;
  text-indent: 0;
}

.cta-bg {
  background: url("../assets/ctabg.jpg");
  background-position: center;
}
</style>
